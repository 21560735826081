.response-text {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.response-text.show {
  opacity: 1;
}

.response-text.hide {
  opacity: 0;
}

.animate-text {
  animation: fadeInOut 5s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.loading-container p {
  margin-top: 10px;
  font-size: 18px;
  color: #888;
}
